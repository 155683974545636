import React, { Component } from 'react'
import Doc from './ALodging-Contract.pdf'
import './Dogovor.css'

class Dogovor extends Component {

    componentDidMount() {
        document.title = 'OOO «ЭЛОДЖИН» - Договор оказания услуг приложения «ALODGING» - alodging.by'
        document.querySelector('body').scrollIntoView()
    }

    render() {
        return (
            <div className="Dogovor">
                <div>
                    <i className="far fa-file-pdf"></i>
                    <a href={Doc} target="_blank" rel="noopener noreferrer">Скачать</a>
                </div>
                <div className="Dogovor-box-1">
                    <div>
                        <div>Утвержден</div>
                        <div>Приказ от 30 июня 2020 года №1-д</div>
                        <div>
                            Настоящая редакция договора
                            опубликована 01 июля 2020 года и
                            вступает в силу с момента публикации
                    </div>
                    </div>
                </div>
                <h1>ДОГОВОР<br/>оказания услуг с использованием программного комплекса ALodging</h1>
                <p>
                    Настоящий договор (далее - Договор) определяет порядок оказания информационных услуг с использованием программного комплекса ALodging, а также взаимные права, обязанности и порядок взаимоотношений между Обществом с ограниченной ответственностью «Элоджин», именуемым в дальнейшем «Исполнитель», в лице Директора Рябцева Сергея Михайловича, действующего на основании Устава, и потребителем услуг, именуемым в дальнейшем «Заказчик», принявшим (акцептовавшим) настоящее предложение (оферту) о заключении настоящего Договора.
            </p>
                <h2>1. ПРЕДМЕТ ДОГОВОРА</h2>
                <p>1.1. В соответствии с условиями настоящего Договора Исполнитель обязуется оказывать Заказчику услуги в сети Интернет с использованием программного комплекса ALodging (далее – Сервис), представляющего собой совокупность компьютерной программы и базы данных, а Заказчик обязуется принять и оплатить услуги в размере и в сроки, предусмотренные настоящим Договором.</p>
                <p>1.2. Полный перечень функций Сервиса, сроки и условия оказания услуг, тарифы, иная информация, имеющая существенное значение для оказания услуг, содержатся на сайте Исполнителя <a href="https://alodging.by/">https://alodging.by/</a> (далее — Сайт).</p>
                <p>1.3. Исполнитель оставляет за собой право вносить изменения и дополнения в настоящий Договор, а также в тарифы, в связи с чем Заказчик обязуется перед получением услуг ознакомиться с актуальной версией Договора, соответствующими тарифами и иной информацией на Сайте.</p>
                <h2>2. ЗАКЛЮЧЕНИЕ ДОГОВОРА</h2>
                <p>2.1. Заказчиком по настоящему Договору может быть юридическое лицо или индивидуальный предприниматель, зарегистрированные по законодательству Республики Беларусь или Российской Федерации, либо гражданин Республики Беларусь или Российской Федерации, которым исполнилось 18 лет и более, которые осуществляют либо намерены осуществлять деятельность по предоставлению жилых помещений для проживания (в аренду, найм и т.п.) третьим лицам на возмездной основе на территории Республики Беларусь и/или Российской Федерации.</p>
                <p>2.2. Публикация (размещение) текста настоящего Договора на Сайте является предложением заключить договор (офертой), адресованным каждому лицу, которое соответствует критериям, установленным для Заказчика. Заключение Договора производится путем присоединения к форме Договора в целом, посредством принятия (акцепта) условий Договора без каких-либо условий, изъятий и оговорок (статья 398 Гражданского кодекса Республики Беларусь).</p>
                <p>2.3. Фактом принятия (акцепта) Заказчиком условий настоящего Договора считается прохождение процедуры авторизации (пункт 3 статьи 408 Гражданского кодекса Республики Беларусь).</p>
                <p>2.4. Настоящий Договор, при условии соблюдения порядка его акцепта, считается заключенным в простой письменной форме (пункты 2 и 3 статьи 404 Гражданского кодекса Республики Беларусь).</p>
                <h2>3. АВТОРИЗАЦИЯ</h2>
                <p>3.1. Авторизация представляет собой совокупность мероприятий, при помощи которых Исполнитель может убедиться, что лицо, выразившее желание использовать Сервис, соответствует критериям, установленным для Заказчика. Авторизация в Сервисе является бесплатной и добровольной.</p>
                <p>3.2. После ознакомления с текстом настоящего Договора заинтересованное лицо подтверждает, что оно соответствует критериям, установленным для Заказчика, путем направления Исполнителю с использованием регистрационной формы на Сайте либо Viber на номер +375 33 371-11-10 заявки с необходимой информацией для формирования профиля Заказчика, включающей фамилию, имя и отчество (при наличии), дату рождения, наименование, учетный номер плательщика, номер мобильного телефона, адрес электронной почты. Исполнитель может запрашивать дополнительную информацию.</p>
                <p>3.3. После предоставления информации, указанной в пункте 3.2. настоящего Договора, заинтересованному лицу необходимо пройти следующие удостоверительные процедуры:</p>
                <ul>
                    <li>(а) ответить на ряд вопросов на Сайте либо путем отправки сообщения в Viber на номер +375 33 371-11-10;</li>
                    <li>(б) подтвердить подключение к Сервису (активацию профиля Заказчика) путем введения в соответствующую форму на поддомене Сайта <a href="https://app.alodging.by">https://app.alodging.by</a> либо в мобильном приложении ALodging кода, полученного в виде sms-сообщения от Исполнителя на указанный номер мобильного телефона. В дальнейшем для получения доступа к Сервису может потребоваться повторное получение кода в виде sms-сообщения, например, в случае получения доступа к Сервису через иной веб-браузер.</li>
                </ul>
                <h2>4. ПОРЯДОК ОКАЗАНИЯ И СДАЧИ-ПРИЕМКИ УСЛУГ</h2>
                <p>4.1.	В состав услуг, оказываемых Исполнителем Заказчику в рамках настоящего Договора, входит:</p>
                <ul>
                    <li>предоставление доступа к Сервису на условиях выбранного тарифа;</li>
                    <li>обеспечение работы Сервиса;</li>
                    <li>обслуживание Сервиса посредством его обновления;</li>
                    <li>оказание технической поддержки по вопросам работы Сервиса в рабочие дни с 9-00 до 18-00 часов по электронной почте, или посредством чата в Viber по телефону +375 33 371-11-10, или в офисе Исполнителя по адресу: 220040, Республика Беларусь, город Минск, улица Некрасова, дом 88, помещение 1-5;</li>
                    <li>предоставление иной информации, связанной с использованием Сервиса.</li>
                </ul>
                <p>4.2. Основными функциями Сервиса являются:</p>
                <ul>
                    <li>автоматическая проверка входящих звонков;</li>
                    <li>автоматическая проверка исходящих звонков;</li>
                    <li>возможность получить полный список отзывов об арендаторе и арендодателе жилья по номеру телефона, УНП и наименованию организации из базы данных Сервиса;</li>
                    <li>возможность добавить отзыв об арендаторе и арендодателе жилья в базу данных Сервиса.</li>
                </ul>
                <p>4.3. Исполнитель осуществляет подключение Заказчика к Сервису:</p>
                <ul>
                    <li>в рамках тестового периода – в день прохождения Заказчиком авторизации;</li>
                    <li>при доступе в рамках выбранного тарифа – с первого рабочего дня, следующего за днем оплаты Заказчиком услуг на условиях выбранного тарифа.</li>
                </ul>
                <p>4.4. Исполнитель блокирует доступ к Сервису в случае:</p>
                <ul>
                    <li>нарушения Заказчиком условий настоящего Договора – до момента устранения нарушений либо расторжения Договора;</li>
                    <li>истечения тестового периода, если Заказчик не оплатил услуги по тарифу до его истечения;</li>
                    <li>истечения оплаченного периода оказания услуг, если Заказчик не оплатил услуги на новый срок;</li>
                    <li>прекращения действия настоящего Договора в случаях, определенных законодательством и настоящим Договором.</li>
                </ul>
                <p>4.5.	За отчетный период оказания услуг по настоящему Договору принимается календарный месяц.</p>
                <p>4.6.	Факт оказания услуг по настоящему Договору в отчетном месяце подтверждается актом об оказании услуг. Акт об оказании услуг составляется Исполнителем и Заказчиком единолично в соответствии с пунктом 1 постановления Министерства финансов Республики Беларусь от 12 февраля 2018 года № 13 «О единоличном составлении первичных учетных документов и признании утратившим силу постановления Министерства финансов Республики Беларусь от 21.12.2015 № 58». Датой составления акта об оказании услуг считается последний календарный день отчетного месяца.</p>
                <p>4.7.	Заказчик безоговорочно соглашается с тем, что если он в течение 5 (пяти) календарных дней со дня оказания ему услуги не заявит каких-либо письменных претензий по оказанной Исполнителем услуге, то это будет рассматриваться как однозначное согласие Заказчика с тем, что услуга оказана Исполнителем своевременно, в полном объеме и надлежащим образом.</p>
                <p>4.8.	Переписка между Исполнителем и Заказчиком по вопросам исполнения Договора осуществляется в электронном виде с контактного электронного адреса Заказчика (указанного в процессе авторизации либо в дальнейшем измененного уполномоченным лицом Заказчика путем подачи письменного заявления в адрес Исполнителя) и электронного адреса Исполнителя, указанного в реквизитах Договора.</p>
                <h2>5. СТОИМОСТЬ УСЛУГ, ПОРЯДОК РАСЧЕТОВ</h2>
                <p>5.1. Предоставление услуг по настоящему Договору осуществляется исключительно на основе 100% предварительной оплаты Заказчиком. В случае истечения оплаченного периода оказания услуги, Исполнитель приостанавливает доступ Заказчика к соответствующим услугам. Возобновление доступа к услугам осуществляется после поступления оплаты за очередной период.</p>
                <p>5.2. Стоимость услуг, оказываемых в рамках настоящего Договора, определяется исходя из выбранного Заказчиком тарифа, действующего в момент выставления счета на оплату услуг. Стоимость услуг указывается без учета НДС, так как Исполнитель применяет упрощенную систему налогообложения без уплаты НДС. Тарифы Исполнителя размещены на Сайте. Изменение тарифа не распространяется на уже оплаченный период оказания услуг.</p>
                <p>5.3. Заказчик осуществляет оплату стоимости услуг в белорусских рублях. При оплате банковской картой в валюте, отличной от белорусских рублей, банк автоматически осуществляет конвертацию валюты в белорусские рубли, при этом списание средств с карты Заказчика происходит по курсу международных платежных систем.</p>
                <p>5.4.	Оплата услуги на условиях выбранного тарифа производится Заказчиком путем перечисления денежных средств на расчетный счет Исполнителя одним из следующих способов:</p>
                <ul>
                    <li>посредством банковской карты с помощью доступных на Сайте сервисов интернет-эквайринга;</li>
                    <li>посредством системы «Расчет» (ЕРИП);</li>
                    <li>посредством безналичного перечисления денежных средств на расчетный счет Исполнителя, указанный в настоящем Договоре. При этом Заказчик обязуется указывать номер и дату счета в платежном документе в целях идентификации платежа;</li>
                    <li>посредством платежных систем, интегрированных в мобильное приложение ALodging.</li>
                </ul>
                <p>5.5. Оплата производится на основании предварительно выставленного счета. Счет для оплаты Сервис формирует автоматически после совершения Заказчиком действий по выбору или продлению определенного тарифного плана в личном профиле на поддомене Сайта <a href="https://app.alodging.by">https://app.alodging.by</a>. После выставления счета у Заказчика возникает возможность оплатить его согласно инструкциям, содержащимся на Сайте, или отменить. Заказчик обязуется оплачивать счета в срок не позднее 5 (пяти) рабочих дней с момента их получения.</p>
                <p>5.6. Для осуществления оплаты посредством ЕРИП необходимо:</p>
                <ul>
                    <li>выбрать пункт «Система «Расчет» (ЕРИП) -> Интернет-магазины/сервисы -> A-Z Латинские домены -> A -> Alodging.by;</li>
                    <li>для оплаты услуги ввести номер заказа;</li>
                    <li>ввести сумму платежа (если не указана);</li>
                    <li>проверить корректность информации;</li>
                    <li>совершить платеж.</li>
                </ul>
                <p>При совершении платежа в кассе банка кассиру необходимо сообщить о необходимости проведения платежа через систему «Расчет» (ЕРИП).</p>
                <p>5.7. При оплате за предоставляемые услуги расходы по переводу денежных средств, оплата услуг банка, оплата услуг платежной системы осуществляются за счет Заказчика.</p>
                <p>
                    5.8. Под датой оплаты понимается дата поступления денежных средств на расчетный счет Исполнителя в полном размере. После успешной оплаты необходимо нажать кнопку «Вернуться на сайт». Изменения для Заказчика (смена или продление тарифного плана) вступят в силу.
            </p>
                <p>5.9. В случае, если после оплаты доступ к Сервису не будет предоставлен, Заказчику необходимо обратиться в службу технической поддержки по телефону +375 (33) 371-11-10 или e-mail: rysergei@yandex.ru.</p>
                <p>5.10. На сумму предварительной оплаты по настоящему Договору проценты за пользование коммерческим займом не начисляются и не уплачиваются.</p>
                <p>5.11. В случае неоказания услуг Заказчик имеет право на возврат денежных средств, оплаченных Исполнителю в качестве предоплаты по настоящему Договору. Для возврата предоплаты Заказчику необходимо обратиться по телефону +375 (33) 371-11-10. При отказе Заказчика от услуг Исполнителя до истечения срока оказания услуг производится перерасчет стоимости услуг за фактическое время пользования ими, по стоимости услуг, определенной соответствующим тарифом, действующим на дату отказа от услуги. Денежные средства подлежат возврату Исполнителем в течение 10 (десяти) рабочих дней с даты досрочного расторжения Договора. В случае расторжения Договора в связи с нарушением его условий Заказчиком стоимость услуг возврату не подлежит.</p>
                <h2>6. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h2>
                <p>6.1.	Исполнитель обязуется:</p>
                <p>6.1.1. Оказывать услуги на условиях согласно настоящему Договору;</p>
                <p>6.1.2. Обеспечивать конфиденциальность информации, полученной от Заказчика, и не предоставлять ее третьим лицам, за исключением случаев, предусмотренных законодательством Республики Беларусь, настоящим Договором и Политикой конфиденциальности Исполнителя;</p>
                <p>6.1.3. Своевременно размещать на Сайте актуальную информацию о Сервисе, а также о любых изменениях, связанных с предоставлением доступа.</p>
                <p>6.2.	Исполнитель вправе:</p>
                <p>6.2.1. Приостановить оказание услуг в случае невыполнения или нарушения Заказчиком условий настоящего Договора;</p>
                <p>6.2.2. Изменить в одностороннем порядке условия настоящего Договора в соответствии с пунктами 14.7 - 14.9 настоящего Договора;</p>
                <p>6.2.3. Отказаться от исполнения настоящего Договора в одностороннем порядке в случае нарушения Заказчиком условий настоящего Договора, направив Заказчику соответствующее уведомление на электронную почту. До момента отказа от Договора Исполнитель вправе прекратить доступ Заказчику к Сервису для предотвращения дальнейших нарушений.</p>
                <p>6.3.	Заказчик обязуется:</p>
                <p>6.3.1. Своевременно и в полном объеме оплачивать услуги, самостоятельно следить за сроками продления периода оказания услуг;</p>
                <p>6.3.2. Определить лиц из числа работников Заказчика, ответственных за ведение профиля Заказчика;</p>
                <p>6.3.3. Обеспечивать меры защиты содержащейся в Сервисе информации от несанкционированного использования третьими лицами - недопущение неправомерного доступа, уничтожения, модификации (изменения), распространения и (или) предоставления информации, а также иных неправомерных действий;</p>
                <p>6.3.4. Самостоятельно обеспечивать работоспособность своего компьютерного, телекоммуникационного оборудования и каналов связи;</p>
                <p>6.3.5. За свой счет оплачивать расходы, связанные с обеспечением достаточного Интернет-трафика для оказания Исполнителем услуг;</p>
                <p>6.3.6. Предоставлять достоверные сведения, необходимые для предоставления доступа к Сервису;</p>
                <p>6.3.7. В течение 5 (пяти) рабочих дней информировать Исполнителя любым доступным способом об изменении ФИО, электронной почты, контактного телефона, иных данных о Заказчике;</p>
                <p>6.3.8. Не вносить изменения в Сервис и в средства программной защиты Сервиса (не взламывать Сервис).</p>
                <p>6.4.	Заказчик не вправе:</p>
                <p>6.4.1. Предоставлять доступ к Сервису третьим лицам;</p>
                <p>6.4.2. Осуществлять продажу, передачу в безвозмездное пользование, безвозмездную передачу, обмен, передачу по сетям телекоммуникаций либо любым иным способом распространять (в том числе в некоммерческих целях) третьим лицам информацию, содержащуюся в Сервисе.</p>
                <h2>7. ТЕСТОВЫЙ ПЕРИОД</h2>
                <p>7.1. Заказчику устанавливается тестовый период продолжительностью 60 (шестьдесят) календарных дней, в течение которого Заказчику предоставляется доступ к функционалу Сервиса, доступному в рамках тарифа «Полный», без взимания платы. Исчисление тестового периода начинается со дня успешного прохождения процедуры авторизации.</p>
                <p>7.2. Предоставление услуг в тестовом периоде осуществляется Исполнителем исключительно для ознакомления с функциональными возможностями Сервиса и оценки соответствия качественных характеристик услуг требованиям Заказчика.</p>
                <p>7.3. Предоставление услуг в тестовом периоде не налагает на Исполнителя финансовых и прочих обязательств по качеству оказания услуг и уровню сервиса. Повторное подключение услуги в тестовом периоде не допускается.</p>
                <p>7.4. Доступ к услуге по истечении тестового периода осуществляется на условиях выбранного Заказчиком тарифа. Заказчик обязан заблаговременно, до истечения тестового периода, выбрать и оплатить соответствующий тариф. В случае, если Заказчик не оплатил услуги по окончании тестового периода, доступ к услуге для Заказчика прекращается.</p>
                <p>7.5. Исполнитель оставляет за собой право прекратить предоставление тестового периода услуги Заказчику в случае злоупотребления последним тестовым периодом, в том числе в случае использования тестового периода в иных целях, кроме тестирования функциональных возможностей Сервиса.</p>
                <p>7.6. Заказчик не вправе использовать Сервис в рамках тестового периода с целью:</p>
                <ul>
                    <li>выполнения работ и оказания услуг в пользу третьих лиц или обеспечения обязательств перед третьими лицами;</li>
                    <li>осуществления деятельности, запрещенной законодательством Республики Беларусь, в том числе: распространения и рекламирования порнографических материалов, призывов к насилию, осуществлению экстремистской деятельности, свержению власти и др., а также деятельности, противоречащей общественным интересам, принципам гуманности и морали, оскорбляющей человеческое достоинство либо религиозные чувства и т.д.;</li>
                    <li>распространения информации, распространение и/или предоставление которой ограничено законодательством, или совершения действий, способствующих ее распространению.</li>
                </ul>
                <p>Исполнитель вправе самостоятельно давать оценку деятельности Заказчика при использовании им функционала Сервиса на предмет соответствия законодательству. При наступлении событий, указанных в настоящем пункте, а также в случае очевидного с точки зрения Исполнителя нарушения Заказчиком законодательства Республики Беларусь либо получения претензий, жалоб от третьих лиц, права которых нарушены в результате соответствующей деятельности Заказчика, Исполнитель вправе произвести блокировку услуги с оповещением об этом Заказчика.</p>
                <h2>8. ОТВЕТСТВЕННОСТЬ СТОРОН</h2>
                <p>8.1. За невыполнение или ненадлежащее выполнение обязательств по настоящему Договору стороны несут ответственность в соответствии с законодательством Республики Беларусь.</p>
                <p>8.2.	Исполнитель не несет ответственности:</p>
                <p>8.2.1. За невозможность доступа к Сервису по причинам, связанным с аварийными ситуациями, с заменой оборудования, программного обеспечения и/или проведения других ремонтных работ на серверном оборудовании, о чем Исполнитель информирует Заказчика путем размещения соответствующей информации на Сайте либо иным способом по усмотрению Исполнителя;</p>
                <p>8.2.2. За невозможность доступа к Сервису по причине неполадок в работе компьютерного, телекоммуникационного оборудования или каналов связи Заказчика и/или третьих лиц (в том числе оборудования оператора, предоставляющего Заказчику услуги связи);</p>
                <p>8.2.3. За перебои в работе глобальной компьютерной сети Интернет и работоспособность Сервиса при недостаточном качестве или скорости соединения при выходе Заказчика в глобальную компьютерную сеть Интернет;</p>
                <p>8.2.4. За ущерб, который может быть нанесен Заказчику третьими лицами во время его работы в глобальной компьютерной сети Интернет. Заказчику рекомендуется самостоятельно устанавливать со своей стороны защитные механизмы и средства для обеспечения безопасности собственных данных от несанкционированного доступа из глобальной компьютерной сети Интернет третьих лиц к аппаратному (компьютер и т.д.) и программному обеспечению Заказчика;</p>
                <p>8.2.5. За иные задержки и сбои в работе Сервиса, возникающие прямо или косвенно по причинам, которые находятся за пределами объективного технического контроля Исполнителя.</p>
                <p>8.3. Ответственность Исполнителя за неисполнение обязательств по настоящему Договору не может превышать стоимости оказания соответствующих услуг за отчетный период.</p>
                <p>8.4. Заказчик несет ответственность за достоверность и полноту сведений, указанных им для получения доступа к Сервису и заключения настоящего Договора. Исполнитель оставляет за собой право в любой момент потребовать от Заказчика (заинтересованного лица при прохождении процедуры авторизации) подтверждения информации, предоставленной о себе, и запросить в связи с этим подтверждающие документы (в частности, документы, удостоверяющие личность). В случае непредставления указанных документов или несоответствия информации Заказчика, указанной в предоставленных им документах, информации, указанной Заказчиком о себе в Сервисе, Исполнитель вправе заблокировать доступ ко всем или некоторым возможностям Сервиса.</p>
                <p>8.5. Заказчику известны важнейшие функциональные свойства оказываемых услуг. Заказчик несет риск соответствия указанных услуг своим ожиданиям и потребностям.</p>
                <p>8.6. Заказчик обязуются использовать Сервис только в законных целях и способами, не нарушающими права третьих лиц. Исполнитель не несет ответственности за нарушение прав третьих лиц, в том числе прав в сфере интеллектуальной собственности, возникших в результате действий Заказчика, совершенных с использованием Сервиса.</p>
                <p>8.7. Заказчик несет полную ответственность за размещение в Сервисе недостоверной информации и информации, распространение и (или) предоставление которой ограничено в соответствии с законодательством, в том числе материалов, содержащих нецензурные слова и выражения, оскорблений, клеветы, сведений, составляющих личную и семейную тайну, тайну телефонных переговоров, почтовых и иных сообщений, касающихся состояния здоровья, порочащих честь, достоинство и репутацию других лиц. Заказчик возмещает Исполнителю любые убытки, понесенные Исполнителем в связи с размещением в Сервисе информации и материалов, содержание которых противоречит законодательству и нарушает права третьих лиц, в том числе права в сфере интеллектуальной собственности.</p>
                <p>8.8. Исполнитель не контролирует и не несет ответственность за интернет-ресурсы третьих лиц, на которые Заказчик может перейти по ссылкам, доступным в Сервисе.</p>
                <p>8.9. Ни одна из сторон ни при каких обстоятельствах не несет никакой ответственности перед другой стороной за потерю данных, упущенную выгоду и любые другие косвенные потери и их последствия, в том числе возникшие в результате перерывов в оказании каких-либо услуг, вне зависимости от того, могла или нет сторона предвидеть возможность таких потерь в конкретной ситуации, за исключением случаев, предусмотренных настоящим Договором.</p>
                <h2>9. ОСОБЫЕ УСЛОВИЯ</h2>
                <p>9.1. Любые действия, совершенные в Сервисе с использованием профиля (учетной записи) Заказчика, считаются совершенными Заказчиком. Заказчик несет ответственность за несанкционированные действия третьего лица с использованием его учетной записи как за свои собственные.</p>
                <p>9.2. Исполнитель не осуществляет премодерацию оставляемых Заказчиком в Сервисе сообщений. Заказчик несет ответственность за любую информацию, которая размещается в Сервисе посредством его профиля. В то же время Исполнитель оставляет за собой право произвести блокировку, исключение, удаление информации, размещенной Заказчиком, без согласия последнего, если такая информация не отвечает требованиям законодательства Республики Беларусь.</p>
                <p>9.3. Сервис защищен законодательством об авторском праве. Заказчик не имеет права создавать копии, изучать технологию, декомпилировать, напрямую или косвенно передавать или распространять Сервис любым третьим лицам, а также разрешать любым третьим лицам доступ и (или) использование функций Сервиса. Исполнитель вправе прекратить Заказчику доступ к Сервису в случае нарушения Заказчиком данных условий.</p>
                <h2>10. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ</h2>
                <p>10.1. Стороны по настоящему Договору освобождаются от ответственности за полное или частичное неисполнение своих обязательств в случае, если такое неисполнение явилось следствием обстоятельств непреодолимой силы, то есть событий, которые нельзя было предвидеть или предотвратить. К вышеуказанным обстоятельствам в контексте настоящего Договора относятся: стихийное бедствие, пожар, война или военные действия, забастовка в отрасли или регионе, повреждения глобальной информационно-телекоммуникационной сети, принятие государственным органом и/или органом местного самоуправления, правовых и/или правоприменительных актов, повлекших невозможность исполнения настоящего Договора и иные действия, находящиеся вне разумного предвидения и контроля сторон.</p>
                <p>10.2. При наступлении обстоятельств непреодолимой силы каждая сторона должна не позднее 5 (пяти) рабочих дней с момента наступления таких обстоятельств известить о них в письменном виде другую сторону. Извещение должно содержать данные о характере обстоятельств, оценку их влияния на возможность исполнения стороной своих обязательств по данному Договору, а также предполагаемые сроки их действия.</p>
                <p>10.3. В случае если любое из вышеуказанных обстоятельств повлияло на исполнение настоящего Договора в срок, то срок исполнения обязательств по настоящему Договору отодвигается на период действия обстоятельства непреодолимой силы.</p>
                <p>10.4. Если действие обстоятельств непреодолимой силы продолжается свыше 1 (одного) месяца, стороны проводят дополнительные переговоры для выявления приемлемых альтернативных способов исполнения настоящего Договора либо настоящий Договор подлежит расторжению в установленном порядке.</p>
                <h2>11. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</h2>
                <p>11.1. Споры и разногласия, вытекающие из настоящего Договора, стороны будут разрешать путем переговоров, а в случае недостижения согласия - в судебном порядке в соответствии с законодательством Республики Беларусь.</p>
                <p>11.2. Сторона, которая полагает, что ее права по Договору нарушены, обязана предъявить претензию второй стороне. Сторона настоящего Договора, получившая претензию, должна письменно сообщить о результатах ее рассмотрения в течение 15 (пятнадцати) календарных дней с даты получения претензии.</p>
                <p>11.3. В случае если стороны не достигнут согласия по спорным вопросам в претензионном порядке, спор разрешается в судебном порядке в соответствии с законодательством Республики Беларусь. Любые споры или разногласия, которые могут возникнуть из настоящего Договора или в связи с ним, подлежат разрешению в компетентном суде по месту нахождения Исполнителя.</p>
                <p>11.4. Доказательствами в суде могут являться факсимильные копии и распечатки электронных писем, если переписка осуществлялась с адресов электронной почты, указанных в настоящем Договоре.</p>
                <h2>12.	ДЕЙСТВИЕ ДОГОВОРА</h2>
                <p>12.1. Настоящий Договор вступает в силу со дня прохождения авторизации Заказчиком.</p>
                <p>12.2. Настоящий Договор может быть расторгнут по основаниям, предусмотренным настоящим Договором и законодательством.</p>
                <p>12.3. После расторжения настоящего Договора Заказчик не вправе использовать Сервис.</p>
                <p>12.4. При расторжении настоящего Договора по любым основаниям его положения будут применяться к отношениям сторон вплоть до полного окончания взаимных расчетов и передачи необходимой документации.</p>
                <p>12.5. Сторона вправе в одностороннем внесудебном порядке отказаться от исполнения Договора, направив другой стороне соответствующее уведомление. Договор считается расторгнутым по истечении 30 (тридцати) календарных дней с момента письменного извещения другой стороны об одностороннем отказе от исполнения настоящего Договора. В случае отказа Исполнителя от исполнения Договора в связи с нарушением его условий Заказчиком (подпункт 6.2.3 Договора) Договор считается расторгнутым в день направления Исполнителем уведомления Заказчику.</p>
                <h2>13. УСЛОВИЯ СБОРА, ОБРАБОТКИ И ПЕРЕДАЧИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h2>
                <p>13.1. Обработка персональных данных осуществляется Исполнителем в соответствии с законодательством Республики Беларусь, международными нормативными актами, а в части обработки персональных данных граждан Российской Федерации – с учетом требований законодательства Российской Федерации.</p>
                <p>13.2. Настоящим Заказчик подтверждает, что полностью ознакомился и принимает Политику конфиденциальности, действующую у Исполнителя. С актуальной версией Политики конфиденциальности можно ознакомиться на Сайте либо в мобильном приложении ALodging.</p>
                <p>13.3. Путем присоединения к настоящему Договору Заказчик дает свое согласие Исполнителю на сбор, обработку и передачу (далее – обработка) своих персональных данных на следующих условиях:</p>
                <p>13.3.1. Согласие Заказчика дается на обработку персональных данных как без использования средств автоматизации, так и с использованием средств автоматизации, без ограничения срока.</p>
                <p>13.3.2. Под персональными данными понимается любая информация, относящаяся к Заказчику как к субъекту персональных данных, в том числе, но не исключительно, имя, фамилия и отчество, дата рождения, адрес места жительства, контактные данные (телефон и электронная почта), платежные реквизиты.</p>
                <p>13.3.3. В ходе обработки с персональными данными могут быть совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.</p>
                <p>13.3.4. Персональные данные обрабатываются и передаются (в том числе, трансгранично) третьим лицам;</p>
                <p>13.3.5. Обработка осуществляется для целей обеспечения функционирования Сервиса и выполнения обязательств Исполнителя перед Заказчиком, в том числе для:</p>
                <ul>
                    <li>заключения настоящего Договора и его дальнейшего исполнения;</li>
                    <li>установления обратной связи с Заказчиком, в том числе для обработки запросов, обращений и информирования о работе Сервиса;</li>
                    <li>проведения Исполнителем акций, опросов, интервью, тестирований и исследований с участием Заказчика;</li>
                    <li>предоставления Заказчику с его согласия новостной рассылки и иных сведений, касающихся Сервиса, а также информации о разработке Исполнителем новых продуктов и услуг, в том числе рекламного характера;</li>
                    <li>контроля и улучшения качества услуг, обеспечения функционирования и безопасности Сервиса;</li>
                    <li>предоставления доступа Заказчику на сайты или сервисы партнеров с целью получения продуктов, обновлений и услуг;</li>
                </ul>
                <p>13.3.6. При оставлении в Сервисе сообщений (комментариев), совершении иных действий они будут доступны для ознакомления всем пользователям Сервиса.</p>
                <p>13.4. Согласие может быть отозвано Заказчиком или его представителем путем направления письменного заявления на электронную почту Исполнителя elodjin@yandex.by. До момента отзыва согласия согласие считается действительным.</p>
                <p>13.5. В случае отзыва Заказчиком или его представителем согласия на обработку персональных данных Исполнитель вправе продолжить обработку персональных данных без согласия Заказчика при наличии оснований, указанных в законодательстве.</p>
                <h2>14. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h2>
                <p>14.1. Правом, применимым к отношениям сторон по настоящему Договору, является законодательство Республики Беларусь.</p>
                <p>14.2. Под рабочими днями в целях исполнения сторонами обязательств по настоящему Договору понимаются рабочие дни исходя из пятидневной рабочей недели (все дни недели, кроме субботы и воскресенья), не являющиеся праздничными или нерабочими днями в соответствии с законодательством Республики Беларусь.</p>
                <p>14.3. Заказчик не вправе передавать третьим лицам права и обязательства по настоящему Договору без письменного согласия Исполнителя.</p>
                <p>14.4. Стороны признают юридическую силу оригиналов за документами, переданными с использованием электронных и факсимильных средств связи. При этом стороны признают, что подпись стороны на полученном документе является аналогом подписи, сделанной собственноручно стороной, от которой исходит документ. Заказчик обязуется самостоятельно и своевременно проверять корреспонденцию, отправленную вышеуказанными способами.</p>
                <p>14.5. Исполнитель может оказывать услуги по настоящему Договору с привлечением третьих лиц.</p>
                <p>14.6. Настоящий договор распространяется на отношения сторон, возникшие до его заключения, начиная с 01 марта 2020 года.</p>
                <p>14.7. Изменения и/или дополнения в настоящий Договор вносятся в одностороннем порядке по решению Исполнителя.</p>
                <p>14.8. Изменения и/или дополнения, вносимые Исполнителем в настоящий Договор в связи с изменением действующего законодательства, вступают в силу одновременно с вступлением в силу изменений в данных актах законодательства.</p>
                <p>14.9. Текст изменений и/или дополнений настоящего Договора или его новая редакция доводится Исполнителем до всеобщего сведения посредством опубликования текста изменений и/или дополнений настоящего Договора или его новой редакции на Сайте и вступает в силу с момента публикации.</p>
                <h2>15. РЕКВИЗИТЫ СТОРОН</h2>
                <p>15.1. Стороны безоговорочно соглашаются считать реквизитами Заказчика информацию, указанную им при оформлении заявки на предоставление услуг (прохождения процедуры авторизации), либо информацию, уточненную уполномоченным представителем Заказчика путем подачи письменного заявления в адрес Исполнителя.</p>
                <p>15.2. Реквизиты Исполнителя:</p>
                <div className="Dogovor-box-2">
                    <div>
                        Общество с ограниченной ответственностью «Элоджин»
                </div>
                    <div>
                        Местонахождение (юридический адрес): 220040, Республика Беларусь, город Минск, улица Некрасова, дом 88, помещение 1-5
                </div>
                    <div>
                        УНП 193244728, ОКПО 502964315000
                </div>
                    <div>
                        р/с (IBAN): BY15PJCB30124212491000000933
                </div>
                    <div>
                        в ЦБУ №402 "Приорбанк" ОАО
                </div>
                    <div>
                        г. Жлобин, микрорайон 16, д.24А
                </div>
                    <div>
                        БИК: PJCBBY2X
                </div>
                    <div>
                        Телефон/Viber: +375 33 371-11-10
                </div>
                    <div>
                        Электронная почта: elodjin@yandex.by
                </div>
                    <div className="Dogovor-sign">
                        Директор ___________________ С.М. Рябцев
                </div>
                </div>
                <div className="Dogovor-copyright">
                    © Все права на текст данного Договора принадлежат ООО «Элоджин».
            </div>
            </div>
        )
    }
}

export default Dogovor