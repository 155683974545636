import React from 'react'
import Overlay from '../../UI/Overlay/Overlay'
import './TryFree.css'

export default (props) => {
    const isMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
        navigator.userAgent
    )

    return (
        <>
            <Overlay color="#1010109d" />
            <div className="TryFree">
                <div className="close-button" onClick={props.onClick}>
                    <div className="close-line-1"></div>
                    <div className="close-line-2"></div>
                </div>
                <div>
                    <p>Напишите администратору в</p>
                    <div className="messanger-container">
                        <div className="messanger">
                            <i class="fab fa-viber"></i>
                            <div>
                                {isMobile ? (
                                    <a
                                        title="Viber"
                                        href="viber://add?number=375333711110"
                                    >
                                        Viber
                                    </a>
                                ) : (
                                    <a
                                        title="Viber"
                                        href="viber://chat?number=+375333711110"
                                    >
                                        Viber
                                    </a>
                                )}
                            </div>
                        </div>
                        <div className="messanger">
                            <i class="fab fa-whatsapp"></i>
                            <div>
                                <a
                                    title="WhatsApp"
                                    href="whatsapp://send?phone=+375333711110"
                                >
                                    WhatsApp
                                </a>
                            </div>
                        </div>
                        <div className="messanger">
                            <i class="fab fa-telegram"></i>
                            <div>
                                <a
                                    title="Telegram"
                                    href="tg://resolve?domain=alodging_by"
                                >
                                    Telegram
                                </a>
                            </div>
                        </div>
                    </div>
                    <p>
                        <a title="Позвонить" href="tel:+375333711110">
                            +375 (33) 371-11-10
                        </a>
                    </p>
                    <p>
                        слово <strong>"Бесплатно"</strong>. В ближайшие 24 часа
                        с вами свяжется наш сотрудник чтобы подключить 2 месяца
                        бесплатного пользования.
                    </p>
                </div>
            </div>
        </>
    )
}
