import React from 'react'

import './Overlay.css'

export default (props) => {
    const color = props.color || '#dcdcdc4d'

    return (
        <div
            className="Overlay"
            style={{ backgroundColor: color }}
            onClick={props.onClick}
        ></div>
    )
}
