import React from 'react'
import './SocialLinks.css'

export default () => {
    return (
        <div className="sociallinks">
            <div className="sociallinks-container">
                <div>Следите за нами</div>
                <div>
                    <a href="https://www.facebook.com/alodging" target="_blanc">
                        <i className="fab fa-facebook-square"></i>
                    </a>
                    <a
                        href="https://www.instagram.com/alodging.by/"
                        target="_blanc"
                    >
                        <i className="fab fa-instagram-square"></i>
                    </a>
                    <a href="https://vk.com/alodging_by" target="_blanc">
                        <i className="fab fa-vk"></i>
                    </a>
                    <a
                        href="https://ok.ru/group/59694753841394"
                        target="_blanc"
                    >
                        <i className="fab fa-odnoklassniki-square"></i>
                    </a>
                    <a
                        href="http://www.youtube.com/channel/UC733Zq8s3UetKvBnluvttQA"
                        target="_blanc"
                    >
                        <i className="fab fa-youtube-square"></i>
                    </a>
                    <a
                        href="https://www.linkedin.com/company/alodging/"
                        target="_blanc"
                    >
                        <i className="fab fa-linkedin"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}
