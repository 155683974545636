import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Layout from './HOC/Layout/Layout'
import Home from './components/Home/Home'
import Tariff from './components/Tariff/Tariff'
import Payment from './components/Payment/Payment'
import Dogovor from './components/Dogovor/Dogovor'
import Policy from './components/Policy/Policy'
import SignUp from './containers/SignUp/SignUp'
import './App.css'

function App() {
    return (
        <Layout>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/tariff" component={Tariff} />
                <Route path="/payment" component={Payment} />
                <Route path="/dogovor" component={Dogovor} />
                <Route path="/politika-konfidencialnosti" component={Policy} />
                <Route path="/registracionnaya-forma" component={SignUp} />
                <Redirect
                    from="/politika-konfidencialnosti.html"
                    to="/politika-konfidencialnosti"
                />
                <Redirect to="/" />
            </Switch>
        </Layout>
    )
}

export default App
