import React from 'react'
import { NavLink } from 'react-router-dom'
import './MainMenu.css'

export default props => {
    return (
        <nav className="MainMenu">
            <NavLink to="/" exact>Главная</NavLink>
            <NavLink to="/tariff">Тарифы</NavLink>
            <NavLink to="/payment">Оплата</NavLink>
            <NavLink to="/registracionnaya-forma">Регистрация</NavLink>
            <a href="https://app.alodging.by">Войти</a>
        </nav>
    )
}